<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">
      <a-form-model-item label="广告id, res_advert.id" prop="advertId" >
        <a-input v-model="form.advertId" placeholder="请输入广告id, res_advert.id" />
      </a-form-model-item>
      <a-form-model-item label="广告详情快照" prop="advertSnapshot" >
      </a-form-model-item>
      <a-form-model-item label="规格尺寸" prop="spec" >
        <a-input v-model="form.spec" placeholder="请输入规格尺寸" />
      </a-form-model-item>
      <a-form-model-item label="投放套餐" prop="timePackage" >
        <a-input v-model="form.timePackage" placeholder="请输入投放套餐" />
      </a-form-model-item>
      <a-form-model-item label="签约公司" prop="signName" >
        <a-input v-model="form.signName" placeholder="请输入签约公司" />
      </a-form-model-item>
      <a-form-model-item label="投放品牌" prop="brandName" >
        <a-input v-model="form.brandName" placeholder="请输入投放品牌" />
      </a-form-model-item>
      <a-form-model-item label="联系人" prop="contactName" >
        <a-input v-model="form.contactName" placeholder="请输入联系人" />
      </a-form-model-item>
      <a-form-model-item label="联系电话" prop="contactPhone" >
        <a-input v-model="form.contactPhone" placeholder="请输入联系电话" />
      </a-form-model-item>
      <a-form-model-item label="投放开始时间" prop="startTime" >
      </a-form-model-item>
      <a-form-model-item label="投放结束开始时间" prop="endTime" >
      </a-form-model-item>
      <a-form-model-item label="单价" prop="price" >
        <a-input v-model="form.price" placeholder="请输入单价" />
      </a-form-model-item>
      <a-form-model-item label="投放数量" prop="count" >
        <a-input v-model="form.count" placeholder="请输入投放数量" />
      </a-form-model-item>
      <a-form-model-item label="订单价格 = 单价*总天数" prop="orderAmount" >
        <a-input v-model="form.orderAmount" placeholder="请输入订单价格 = 单价*总天数" />
      </a-form-model-item>
      <a-form-model-item label="应付款 " prop="payAmount" >
        <a-input v-model="form.payAmount" placeholder="请输入应付款 " />
      </a-form-model-item>
      <a-form-model-item label="支付时间" prop="payTime" >
        <a-date-picker style="width: 100%" v-model="form.payTime" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="三方支付订单号" prop="outTradeNo" >
        <a-input v-model="form.outTradeNo" placeholder="请输入三方支付订单号" />
      </a-form-model-item>
      <a-form-model-item label="是否开票,1:是,0:否" prop="isInvoice" >
        <a-input v-model="form.isInvoice" placeholder="请输入是否开票,1:是,0:否" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getAdvert, addAdvert, updateAdvert } from '@/api/order/advert'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    CustomDictTag,
  },
  data () {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        advertId: null,

        advertSnapshot: null,

        spec: null,

        timePackage: null,

        signName: null,

        brandName: null,

        contactName: null,

        contactPhone: null,

        startTime: null,

        endTime: null,

        price: null,

        count: null,

        orderAmount: null,

        payAmount: null,

        createTime: null,

        payTime: null,

        outTradeNo: null,

        isInvoice: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        advertId: [
          { required: true, message: '广告id, res_advert.id不能为空', trigger: 'blur' }
        ],
        advertSnapshot: [
          { required: true, message: '广告详情快照不能为空', trigger: 'blur' }
        ],
        spec: [
          { required: true, message: '规格尺寸不能为空', trigger: 'blur' }
        ],
        timePackage: [
          { required: true, message: '投放套餐不能为空', trigger: 'blur' }
        ],
        signName: [
          { required: true, message: '签约公司不能为空', trigger: 'blur' }
        ],
        brandName: [
          { required: true, message: '投放品牌不能为空', trigger: 'blur' }
        ],
        contactPhone: [
          { required: true, message: '联系电话不能为空', trigger: 'blur' }
        ],
        startTime: [
          { required: true, message: '投放开始时间不能为空', trigger: 'blur' }
        ],
        endTime: [
          { required: true, message: '投放结束开始时间不能为空', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '单价不能为空', trigger: 'blur' }
        ],
        count: [
          { required: true, message: '投放数量不能为空', trigger: 'blur' }
        ],
        orderAmount: [
          { required: true, message: '订单价格 = 单价*总天数不能为空', trigger: 'blur' }
        ],
        payAmount: [
          { required: true, message: '应付款 不能为空', trigger: 'blur' }
        ],
        isInvoice: [
          { required: true, message: '是否开票,1:是,0:否不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        advertId: null,
        advertSnapshot: null,
        spec: null,
        timePackage: null,
        signName: null,
        brandName: null,
        contactName: null,
        contactPhone: null,
        startTime: null,
        endTime: null,
        price: null,
        count: null,
        orderAmount: null,
        payAmount: null,
        createTime: null,
        payTime: null,
        outTradeNo: null,
        isInvoice: null,
        remark: null,
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getAdvert({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateAdvert(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addAdvert(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
